#contact{
    width: 80vw;
    height: auto;
    margin: auto;
    font-family: 'Ubuntu';
    margin-bottom: 70px;
}

#contactWrapper{
    display: flex;
    justify-content: space-evenly;
    margin: 50px 0 0 0;
}

#contactData{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.comapny-data{
    display: block;
    margin-top: 20px;
    font-weight: 100;
    font-size: .8rem;
}

#email{
    margin-top: 50px;
    font-weight: 400;
    font-size: 1.1rem;
}

.icon-wrapper{
    border: 2px solid #1ED3F4;
    border-radius: 50%;
    box-shadow: 0 0 4px #1ED3F4;
    width: 20px;
    height: 20px;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.social-media-icon{
    color: #1ED3F4;
    font-size: .7rem;
}

#socialMedia{
    margin-top: 20px;
    flex-direction: row;
}

#contactForm{
    width: 40vw;
}

.contact-input{
    width: 100%;
    outline: none;
    height: 40px;
    border-radius: 20px;
    background-color: #1c2034;
    color: white;
    border: none;
    padding: 0 0 0 20px;
    border: none;
    margin: 5px 0 0 10px;
}

.message{
    height: 200px;
    resize: none;
    padding-top: 20px;
}

.input-description{
    margin: 15px 0 5px 25px ;
    font-size: .9rem;
    font-weight: lighter;
    font-family: 'Ubuntu';
    display: block;
}

.contact-button{
    float: right;
    margin: 20px -30px 0 0 ;
}

.feedback{
    text-align: center;
    padding: 10px 0 0 30px;
}

.error{
    color: rgb(218, 64, 64);
}

.success{
    color: rgb(60, 240, 120);
}

.wrong{
    border: 2px solid rgb(172, 11, 11);
}

@media only screen and (max-width: 768px) {
    #contact{
        padding: 0;
        padding-top: 60px;
        width: 100%;
    }
    
    #contactData{
        order: 2;
        width: auto;
        align-items: flex-start;
    }

    #contactWrapper{
        flex-direction: column;
        align-items: center;
        margin: 20px 0 0 0;

    }

    #contactForm{
        width: 75vw;
        margin-right: 20px;
    }

    #contactLogo{
        display: none;
    }

    .contact-input{
        height: 50px;
        border-radius: 30px;
        padding: 0 0 0 20px;
        margin: auto;
    }

    .contact-button{
        margin: 20px -20px 0 0 ;
    }

    .comapny-data{
        font-size: 1.2rem;
    }

    .message{
        height: 200px;
        width: calc(75vw - 20px);
        padding: 20px;
        resize: none;
    }
    
    #socialMedia{
        width: 75vw;
        text-align: end;
        justify-content: flex-end;
    }

    .icon-wrapper{
        width: 30px;
        height: 30px;
    }

    .social-media-icon{
        font-size: 1rem;
    }
    
}

@media only screen and (min-width: 1920px) {
    #contact{
        width: 1400px;
    }

    #contactForm{
        width: 800px;
    }
}
