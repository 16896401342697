.a{
    fill: #0a4cae;
  }
  
.b {
  fill:url(#a);
}  

svg{
  fill:none;
  /* height: 100%; */
}
#glowingGigLogo{
  /* background-color: red; */
  filter: drop-shadow(0 0 10px #1ED3F4);
}
