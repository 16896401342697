#projects{
    height: auto;
    padding: 100px;
    max-width: 1200px;
    margin: auto;
}

#projectsWrapper{
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: space-evenly;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.projectDescription{
    text-align: justify;
}

.project{
    padding: 20px 0 20px 50px;
    width: 200px;
    text-align: start;
    justify-content: center;
    background-color: transparent;
    font-weight: 600;
    cursor: pointer;
    font-size: .9rem;
    position: relative;
}

.project p {
    margin: 0;
}

#project1{
    border-radius: 20px 20px 0 0 ;
}

#project2{
    border-top: 1px solid rgba(216, 204, 245, 0.212);
}

#project3{
    border-radius: 0 0 20px 20px;
    border-top: 1px solid rgba(216, 204, 245, 0.212);
}

#projectsWrapper input[type="radio"] {
    opacity: 0;
    position: absolute;
}

.background{
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #1A1F34;
    display: inline-block;
    position: absolute;
    z-index: -1;
}

.project input[type='radio']:checked ~ p{
    color: #1ed3f4;
}

.project input[type='radio']:checked ~ .background{
    background-color: #252b45;
}

#arrowWraper{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-right: 60px;
    flex-grow: 2;
}

.projects-icon{
    font-size: 1.5rem;
    height: 7vh;
    color: #1ED3F4;
    filter: drop-shadow(0 0 4px #1ED3F4);
    cursor: pointer;
}

#projectsBox{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 5vh;
}

.mobile-project{
    display: none;
}

@media only screen and (max-width: 768px) {
    #projects{
        align-items: center;
        margin: 0;
        width: 100vw;
        padding: 60px 0 0 0;
    }

    .projects-icon{
        margin: 0 !important;
    }

    #projectsBox{
        display: none;
    }

    .mobile-project{
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .mobile-project-selector{
        display: flex;
        align-items: center;
        flex-direction: row;
        width: 80vw;
        margin: auto;
        justify-content: space-between;
    }

    .mobile-project-name{
        padding: 13px 20px;
        margin: 20px 15px 15px 15px;
        color: #1ED3F4;
        background-color: #1a1f34;
        text-align: center;
        border-radius: 40px;
    }

    .projectDescription{
        width: 80vw;
        text-align: justify;
    }
}