body{
    background-color: #111325;
    overflow-x: hidden;
    color: white;
    scrollbar-width: none;
}

body::-webkit-scrollbar {
    width: 0 !important;
}

.arrow{
    display: inline-flex;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 2px solid #1ED3F4;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    filter: drop-shadow(0 0 3px #1ED3F4);
    padding: 2px;
}

.arrow-icon{
    color: #1ED3F4;
    transform: translate(-50% -50%);
    position: relative;
    height: 10px;
    width: 10px;
}

h1{
    font-family: 'Ubuntu', sans-serif;
    font-size: 1.7rem;
    font-weight: 800;
    color: white;
    margin: auto;
    display: block;
    text-align: center;
    margin-top: 3vh;
}

.wave{
    width: 120vw;
    margin-left: -10vw;
    z-index: -2;
}

.desktopWave{
    display: block;
}

.mobileWave{
    display: none;

}

html,
body {
    overflow-x: hidden;
    position: relative;
    height: 100%;
}

.noselect {
    -webkit-touch-callout: none; 
      -webkit-user-select: none; 
       -khtml-user-select: none; 
         -moz-user-select: none; 
          -ms-user-select: none; 
              user-select: none; 
  }

@media only screen and (max-width: 768px) {
    .desktopWave{
        display: none;
    }

    .mobileWave{
        display: block;
    }
}

@media only screen and (min-width: 1921px) {
    .desktopWave{
        width: 100vw;
        height: 300px;
    }

    .big{
        width: 100vw;
        height: 400px;
    }
}