#menu{
    position: fixed;
    top: 50px;
    left: 0;
    width: 100%;
    background-color: #111325;
    z-index: 4;
    text-transform: uppercase;
}

#menu li{
    padding: 20px;
    display: block;
    width: 100%;
}