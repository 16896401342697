#success{
    height: auto;
}

#successesCards{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 100px;
    max-width: 1200px;
    margin: auto;
    margin-top: 7vh;
    margin-bottom: 3vh;
}

.success-card{
    width: 300px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-items: start;
    padding-bottom: 20px;
    background-color: '#1a1f34';
    cursor: pointer;
}

.success-card-text{
    text-align: center;
    z-index: 1;
    height: 150px;
}

.success-card-title{
    margin-top: 80px;
    margin-bottom: -10px;
    line-height: 20px;
}

.succes-card-bottom{
    display: flex;
    flex-direction: column;
    z-index: 1;
    position: relative;
    height: 270px;
}

.success-card-description{
    font-size: .8rem;
    line-height: 1.5;
    display: flex;
    justify-self: flex-end;
    text-align: justify;
    padding: 40px 20px;
}

.success-arrow{
    right: 20px;
    bottom: 0;
    position: absolute;
    z-index: 3;
}

.background-image{
    position: absolute;
    width: 300px;
    z-index: 0;
    opacity: .5;
    border-radius: 20px;
}

.success-card-arrow{
    display: none;
}

a{
    text-decoration: none;
    color: white;
}

.mobile-successes{
    display: none;
}

@media only screen and (max-width: 768px) {
    .mobile-successes{
        margin: auto;
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: center;
        background-color: #1a1f34;
        border-radius: 35px;
        width: 80vw;
        margin-top: 20px;
        box-shadow: 0 7px 10px #0a0c16;
        height: 430px;
    }

    #successesCards{
        display: none;
    }

    #success{
        height: auto;
        width: 100vw;
        padding-top: 60px;
    }

    .mobile-success-header{
        display: flex;
        align-items: center;
        /* background-color: red; */
        height: 150px;
        width: 60vw;
    }

    .mobile-success-name{
        margin: 60px auto;
        text-align: center;
    }

    .successDescription{
        width: 70vw;
        text-align: justify;
        font-weight: 300;
        padding-bottom: 40px;
    }

    .success-arrow{
        position: absolute;
        bottom: 20px;
    }

    .projects-icon{
        margin: 10px
    }
}

@media only screen and (min-width: 1920px) {
    #successesCards{
        width: 1400px;
    }
}