#navbar{
    height: 100px;
    background-color: #111325;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    position: fixed;
    width: 100%;
    top: 0;
    font-family: 'Ubuntu';
    z-index: 4;
}

#navbarWrapper{
    width: 1200px;
    padding: 0 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

#navbarLogo{
    cursor: pointer;
    height: 100%;
    /* background-color: red; */
    /* width: 100px; */
    /* height: 100px; */
}

#navbarWrapper ul{
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    padding: 10px;
    display: flex;
    align-items: center;
    padding: 10px 0 10px 10px;
}

#navbar li {
    float: left;
    display: block;
    color: white;
    text-align: center;
    margin: 0 25px;
    text-decoration: none;
    font-size: .9rem;
    cursor: pointer;
}

#mobile-menu{
    display: none;
}

#language{
    text-transform: uppercase;
    margin: 0 0 0 25px !important;
}

.icons-nav{
    display: inline-flex;
    flex-direction: row;
    align-items: flex-end;
    height: 70px;
    vertical-align: baseline;
}

#EUFlag{
    margin-left: 20px;
    text-align: center;
}

#EUFlag p{
    display: block;
    margin: 5px 0 0 0;
    font-size: .6rem;
    line-height: .75em;
}

#EUFlag img{
    height: 40px;
}

@media only screen and (max-width: 768px) {

    #navbar ul{
        display: none;
    }

    .memu-icon{
        height: 3px;
        background-color: #ffffff;
        align-content: space-around;
        transition: .2s ease-in-out;
    }

    #mobile-menu{
        height: 15px;
        width: 25px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px 40px 20px 20px;
    }

    #navbar{
        height: 70px;
        justify-content: space-between;
        padding-left: 20px;
        display: inline-flex;
        margin: auto;
    }

    .icons-nav{
        height: 50px;
    }

    #navbarWrapper{
        padding: 0;
        width: auto;
    }
    
    #EUFlag img{
        height: 30px;
    }

    #EUFlag p{
        font-size: .5rem;
        margin: 0;
    }

    #language{
        margin: 0 !important;
    }
}