#home{
    height: 90vh;
    padding-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 80px;
}

#homeWrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    padding: 100px;
}

#homeText{
    font-family: 'Ubuntu';
    color: white;
    font-size: 1.7rem;
    font-weight: lighter;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    text-align: center;
    margin: 0;
}

#glowingLogo{
    max-width: 55%;
    max-height: 80vh;
    margin-right: -50px;
}

.button{
    background-color: transparent;
    padding: 8px 16px;
    border-radius: 80px;
    border: 2px solid #1ED3F4;
    box-shadow: 0 0 6px #1ED3F4;
    color: white;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 400;
    font-size: 1.1rem;
    outline: none;
    cursor: pointer;
    transition: .2s ease;
}

.button:hover{
    box-shadow: 0 0 10px #1ED3F4;
}

@media only screen and (max-width: 768px) {
    #glowingLogo{
        max-width: 80vw;
        margin: 0;
    }

    #homeWrapper{
        flex-direction: column;
        padding-top: 50px;
    }

    #homeText{
        font-size: 1rem;
    }

    .button{
        padding: 10px 20px;
        font-size: 1.3rem;
        font-weight: 600;
    }
}