#offer{
    height: auto;
}

.offer-card{
    color: white;
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: justify;
    
}

.offer-card-icon{
    height: 100px;
}

#offerCards{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 100px;
    max-width: 1200px;
    margin: auto;
    margin-top: 7vh;
    margin-bottom: 3vh;
}

.card-title{
    color: #1ED3F4;
    margin-bottom: 20px;
}

@media only screen and (max-width: 768px) {
    #offer{
        padding-top: 60px;
    }

    #offerCards{
        flex-direction: column;
        align-items: center;
    }

    #offerCards{
        margin-top: 3vh;
        width: auto;
    }

    .offer-card{
        margin-bottom: 30px;
        width: 80vw;
    }
}