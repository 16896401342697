#about{
    height: auto;
    margin: auto;
    z-index: 20;
}

#about-description{
    padding: 30px 250px;
    text-align: justify;
    max-width: 1000px;
    margin: auto;
}

.about-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    background-color: #1a1f34;
    border-radius: 20px;
}

.team-top{
    height: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.team-img{
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 1px solid #636a90;
    margin-top: -10%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.56);
}

.team-name{
    margin: 40px 0 0 0;
}

.team-position{
    margin: 0 20px 40px 20px;
    text-align: center;
    font-weight: 500;
    font-size: 1.1rem;
}

.team-description{
    font-size: .8rem;
    line-height: 1.6;
    display: flex;
    justify-self: flex-end;
    text-align: justify;
    padding: 0 20px 20px 20px;
    font-weight: lighter;
}

#aboutCards{
    display: flex;
    justify-content: space-between;
    padding: 0 100px;
    max-width: 1200px;
    margin: auto;
    margin-top: 7vh;
    margin-bottom: 3vh;
}

@media only screen and (max-width: 768px) {
    #about{
        padding-top: 60px;
    }
    
    #about-description{
        padding: 20px 0;
        width: 80vw;
        margin: auto;
        font-weight: 300;
    }

    #aboutCards{
        flex-direction: column;
        width: auto;
        align-items: center;
    }

    .team-description{
        font-weight: 300;
        font-size: 1rem;
        width: 70vw;
    }

    .about-card{
        width: 80vw;
        background-color: #1a1f34;
        border-radius: 35px;
        margin-bottom: 50px;
    }
}